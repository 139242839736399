.employeePlans {

  h4 {
    padding-left: .8em;
    margin-top: .5em;
    color: $blue-1;
    font-family: $fontMain;
    font-weight: bold;
    text-transform: uppercase;
  }

  .btn-secondary {
    @include button-variant($blue-2, $blue-2);
    font-family: $fontMain !important;
    background-image: none;
    border-radius: 0;
    margin: .15em;
  }

  .tab-pane {
    padding-top: 1em;
  }

  .nav-tabs {
    .nav-link {
      font-family: $fontMain;
      font-weight: bold;
    }
  }

  .switch {
    position: absolute;
    right: 14px;
    margin-top: 10px;
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $blue-2;
      border-color: $blue-2;
    }
  }

  .no-plans-message {
    display: flex;
    justify-content: center;
    margin: 1rem;
    padding: 1rem;

    border-style: solid;
    border-width: revert;
    border-color: #16709E;
  }
  
  .filter {
    text-align: center;
  }

  @media screen and (max-width: 600px) {
    .nav-tabs {
      margin-top: 20px;
    }
    .switch {
      margin-top: -25px;
      left: 20px;
    }
  }
}
