.notFound {
  .header {
    background-color: $blue-1;
    text-align: center;
    padding: 1em;

    h1 {
      line-height: 1.1;
      letter-spacing: .5px;
      text-transform: uppercase;
      color: white;
      font-family: $fontMain;
      font-weight: 800;
    }
  }

  .content {
    padding-top: 5rem;
  }

  p {
    color: $gray-1;
    font-size: 1.25rem;
    line-height: 1.75;
    margin-bottom: 1.5rem;
  }

  @media only screen and (min-width: 1024px) {
    h1, h2 {
      font-size: 3.25rem;
      text-transform: uppercase;
      line-height: 1.2;
      margin-bottom: .6em;
    }

    h2 {
      color: $black-2;
      font-family: $fontMain;
      font-weight: bold;
    }
  }
}