$black-1: #191919;
$black-2: #2b2b2b;
$black-3: #333;

$gray-1: #43505E;
$gray-2: #8596A8;
$gray-3: #C9D4DE;
$gray-4: #F1F0F0;
$gray-5: #ececec;
$gray-6: #d9d9d9;

$blue-1: #002060; //nepc blue
$blue-2: #16709E; //middle blue
$blue-3: #004879; 
$blue-4: #6ED0F7; //sky blue

$green-1: #8CC94A;

$header-big-height: 250px;
$header-small-height: 180px;
$footer-big-height: 90px;
$footer-small-height: 45px;
