.systemMessagesPopup {
  font-family: $fontMain;
  font-weight: 600;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 21324;

  .messages {
    position: fixed;
    width: 300px;
    max-height: 400px;
    background-color: white;
    border: 1px solid gray;
    overflow-y: auto;

    .message {
      font-size: 0.8rem;
      padding: 0.5rem;
      border: 1px solid darkgray;
      background-color: white;
    }

    .title {
      font-size: 1rem;
      font-weight: bold;
      color: #595959;
      margin-bottom: 0px;
    }

    .postedDate {
      font-size: 8pt;
      font-style: italic;
      color: darkgray;
      padding-bottom: 0.5rem;
      display: inherit;
      font-weight: 100;
    }

    .short {
      color: #494949;
    }
  }

  .nav-link {
    padding: 0;
  }
}

.announcementsBadge {
  border-radius: 5rem;
  line-height: 0.9em;
  font-size: 100%;
}

.announcementsUnreadBadge {
  float: right;
  .badge {
    border-radius: 5em;
    line-height: 0.5;
  }
}

.message-mark-all-read {
  float: right;
}
