.note {
  border-top: 1px solid $gray-3;
  padding: 0.7em 1em;

  h4 {
    font-family: $fontMain;
    font-weight: bold;
    font-size: $font-m;
  }
}
