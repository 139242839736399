@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

@font-face {  font-family: 'FontAwesome';  src: url('https://cdn2.hubspot.net/hubfs/2529352/fonts/fontawesome-webfont.svg') format('svg'), url('https://cdn2.hubspot.net/hubfs/2529352/fonts/fontawesome-webfont-1.woff') format('woff');}

$fontMain: 'Open Sans', sans-serif;

$font-s: 12pt;
$font-m: 14pt;
$font-l: 16pt;

// Globally customize Bootstrap:
//   $font-family-sans-serif: 'openSansRegular', sans-serif;
