.review {
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;

  .options button {
    margin-right: 0.25rem;
    margin-top: 0.25rem;
  }

  .userListGrid {
    min-height: 10rem;
    width: 30rem;
  }
}