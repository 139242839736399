
html {
  color: $gray-1;
  line-height: 1.5;
}

body {
  font-family: $fontMain;
  font-size: $font-s;
  background-color: $gray-3 !important;
}

.nav-link, .gridLink {
  color: $blue-2;
}

input[type="date"] {
  max-width: 12rem;
}

.ag-group-value, .ag-group-child-count{
  font-size: 14px;
  font-weight: 500;
}
