.pdf-main-container {
  padding: 0 0 0 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav {
  margin-top: 10px;
}

.btn-round-xl {
  margin: 6px 18px;
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 35px;
  font-size: 44px;
  line-height: 1.33;
  position: relative;
  border-color: $blue-2;
  color: $blue-2;
  background-color: white;
  outline: none !important;
  cursor: pointer;
}

.btn-round-xl:disabled {
  border-color: #6c757d;
  color: #6c757d;
  cursor: default;
}

.btn-round:disabled {
  border-color: #6c757d;
  color: #6c757d;
  cursor: default;
}

.btn-round {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  border-radius: 30px;
  font-size: 0.60rem;
  line-height: 1.33;
  border-color: $blue-2;
  color: $blue-2;
  background-color: white;
  outline: none !important;
  cursor: pointer;
}

.btn-zoom:disabled {
  border-color: #6c757d;
  color: #6c757d;
  cursor: default;
}

.btn-zoom {
  margin: 6px 9px 0px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  border-radius: 30px;
  font-size: 12px;
  line-height: 1.33;
  position: relative;
  border-color: $blue-2;
  color: $blue-2;
  background-color: white;
  outline: none !important;
  cursor: pointer;
  z-index: 1;
}

.pdf-canvas {
  width: 470px;
  max-height: 660px;
  margin-top: 5px;
}

.pdf-canvas-full-screen {
  padding-right: 5px;
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: "0px auto";
  max-height: 94vh;
  z-index: 0;
}

.canvas-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid;
  border-width: 1px;
  border-color: black;
}

.pdf-thumbnail-canvas {
  max-width: 90px;
  max-height: 90px;
  margin: 0 7px;
}

.pdf-thumbnail-active-canvas {
  border: solid;
  border-color: #06af8f;
}

.helper-buttons {
  display: flex;
  justify-content: flex-end;
}

.zoom-butons-area {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  float: right;
  margin-right: -15px;
}

.downloadPDF,
.expandPDF {
  margin: 5px;
}

.pdf-thumbnails {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.pdf-thumbnails-fullScreen {
  min-width: 850px;
  max-width: 850px;
}

.btn-margin-left {
  margin-left: 0px;
}

.btn-margin-right {
  margin-right: 0px;
}

.close-icon {
  position: relative;
  bottom: 2px;
}

.zoom-icon {
  position: relative;
  bottom: 2px;
  right: 3px;
}

.no-documents {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}

@media screen and (max-width: 1200px) {
  .btn-margin-left {
    margin-left: 250px;
  }

  .btn-margin-right {
    margin-right: 250px;
  }

  .pdf-canvas-full-screen {
    max-height: 80vh;
    max-width: 92vw;
  }
}

@media screen and (max-width: 800px) {
  .btn-margin-left {
    margin-left: 120px;
  }

  .btn-margin-right {
    margin-right: 120px;
  }

  .pdf-canvas-full-screen {
    max-height: 90vh;
  }
}