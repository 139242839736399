.contentFieldsArea div:nth-last-child(1)>hr {
  display: none;
}

.loading-container {
  width: 100%;
  height: 300px;
}

.contentFieldsArea {
  min-height: 3rem;
  margin-top: 0.5rem;

  h6 {
    font-weight: bolder;
  }

  p:has(strong:only-child){
    margin: 1rem 0 0.25rem 0;
  }
  .page-container {
    p {
      padding: 0;
      margin: 0;
    }
    .doc-modal-title {
      margin-top: 1rem;
    }
  }
}

.contentFieldsArea span {
  font-family: $font-family-sans-serif;
  white-space: pre-wrap;
  overflow: hidden;
}

.notesModalArea {
  // padding: 1rem;
}

.messagesModalArea {
  padding: 2rem;
}

.fpl-disclaimer-print {
  display: none;
}

.fpl-disclaimer-web.active {
  background-color: #f3f9f5;
  border-color: $blue-1;
  border-radius: 4px;
}

.fpl-evest-source {
  font-style: italic;
  font-size: 10pt;
}

.fpl-header {
  a {
    margin: .5rem 0 .25rem 0;
    text-decoration: none;
    display: inline-flex;
    i {
      align-self: center;
      font-size: 0.8rem;
      margin: .2rem 0.5rem 0 1rem;
    }
  }
  padding-bottom: 1rem;
}

.whatIsLink {
  padding: 0 0 0 0.2rem;
  font-size: smaller;
  color: $blue-2;
}

a {
  color: $blue-2;
}

.managerDisclaimer {
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  font-size: 15px;
}

.dueDiligenceStatusColumn {
  min-width: 9rem;
}

.ratingColumn {
  min-width: 7rem;
}

.fpl-disclaimer-container {
  padding-bottom: 1rem;

  a {
    color: $blue-2;
  }
}

.fpl-disclaimer-web {
  padding: 0.5rem 1rem 1rem 1rem;

  ul {
    margin-bottom: 0;
  }

  .diclaimer-content {
    color: #6b6b6b;
    font-size: 10pt;
    font-weight: normal;
    margin-left: 35px;
  }

  .nav-link {
    color: gray;
  }

  .fpl-icon {
    margin-right: 0.25rem;
  }

  .fpl-disclaimer-title {
    color: #002161;
    font-size: 15pt;
    font-weight: bold;
  }

  .fpl-list-style {
    list-style-type: square;
  }
}

.research-writeups-disclaimer {
  margin-bottom: 0;
  font-style: italic;
}

.documentsView {
  clear: both;
  padding: 1.25rem calc(1.25rem + 15px) 1.25rem calc(1.25rem + 15px);

  .filters {
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
    .filters-component {
      flex-direction: column;
      align-items: baseline;
      flex-wrap: wrap;
      margin: 5px 0 0 0;

      animation: fadeIn 0.5s;

      .filters-label {
        margin: 0 1rem 0 0;
        padding: 5px 0 0 0;
      }
      .filters-label-md {
        padding: 5px 0 0 0;
        min-width: 86px;
      }
      .filters-selects {
        display: inline-flex;
        padding: 0px;
        margin: 0 1rem 0 0;
        .btn {
          white-space: nowrap;
          padding: 1px 5px;
        }
        .dropdown-toggle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-width: 13rem;
        }
        .dropdown-menu {
          max-height: 40rem;
          overflow-x: hidden;
        }
        .dropdown-item {
          white-space: nowrap;
          width: 100%;
          overflow: hidden;  
          text-overflow: ellipsis;
        }
        p {
          margin: 0 1rem;
        }
      }
      .filterButton {
        margin-right: 0.25rem;
        margin-top: 0.25rem;
        transition: color 0.10s ease-in-out, background-color 0.10s ease-in-out, border-color 0.10s ease-in-out, box-shadow 0.10s ease-in-out;
      }
    }

    .row.static-fields,
    .row.dynamic-fields {
      justify-content: flex-start;
      margin: 0 !important;
    }


    .selected {
      background-color: $blue-2;
      color: white;
    }
  }

  .diverse-switch {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    padding-bottom: 0.3rem;
  }

  .buttons-container {
    display: inline-flex;
    gap: 1rem;
    min-width: 200px;
    justify-content: flex-end;
    button {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
  .gridArea {
    height: 27rem;
    width: 100%;
    .ag-group-value {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: normal;
        align-items: normal;
        align-content: normal;
        min-width: 20rem;
      :nth-child(1) {
          display: block;
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: auto;
          align-self: auto;
          order: 0;
      }
      :nth-child(2) {
          display: block;
          flex-grow: 0;
          flex-shrink: 1;
          flex-basis: auto;
          align-self: auto;
          order: 0;
      }
    }
  }

  .fpl-grid-top {
    display: flex;
    align-items: center;
    padding: 0.5rem 0 1rem 0;
  }
  
  .download-button {
    display: flex;
    justify-content: center;
    padding: 5px;
    padding-left: 9px;
    font-size: 20px;
   	color: $blue-2;
  }

  .ag-bl-overlay {
    .ag-overlay-panel {
      .ag-overlay-wrapper {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .gridLink {
    padding: 0 0;
    font-size: 14px;
    color: $blue-2;
  }

  i.fa-check {
    padding: 5px;
    width: 20px;
    height: 20px;
    color: #6b6b6b;
  }

  .owned-led-box {
    display: flex;
    margin: 2px 25px;
    width: 22px;
    height: 22px;
  }

  .active.nav-link {
    color: $blue-1;
    background-color: #f3f9f5;
  }

  .detailTitle {
    font-weight: bold;
  }

  .strategyDownloadDetail {
    .detailTitle {
      flex-grow: 1;
    }
  }

  .detailUpdatedOn {
    font-weight: normal;
  }

  .managerLink {
    .gridLink {
      font-size: 1rem;
      color: $blue-2;
    }
  }

  .printItem {
    display: none;
  }

  .documentsMenuContainer {
    padding-bottom: 1rem;
  }

  .notification-flag {
    background-color: rgb(252, 66, 66);
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 3rem;
    margin-left: 0.5rem;
    margin-bottom: 0.3rem;
  }

  .documentsMenuRoot {
    padding-right: 0rem;
  }

  .downloadCard {
    max-height: 90px;
    max-width: 250px;
    min-width: 18%;
    position: absolute;
    right: 14px;
    padding: 4px;
    z-index: 1000;

    .downloadText {
      font-style: italic;
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .export-to-csv-container {
    width: 86px;
    position: absolute;
    right: 194px;
    padding: 4px;
    z-index: 1000;
    margin-right: 4px;
  }

  .reset-buttons-line {
    display: inline-flex;
    gap: 0.5rem;
    width: 100%;
    align-items: center;
    justify-content: right;
    padding: 5px 0;

    .collapse-table,
    .expand-table {
      width: 6rem;
      padding: 0 4px;
      z-index: 1000;
      cursor: pointer;
      color: #007bff;
      font-style: italic;
      text-decoration: underline;
    }  
  }
}

.back-button {
  padding-left: 15px;
  cursor: pointer;
  color: #16709E;
  .icon {
    font-size: .8rem;
  }
  .text {
    padding-left: 2px;
  }
}

.diclaimer-content {
  color: #6b6b6b;
  font-size: 10pt;
  font-weight: normal;
  margin-left: 35px;
}

.meetingNotesDetail,
.messagesModalDetail {
  @media (min-width: 576px) {
      max-width: 80vw;
      margin: 1.75rem auto;
  }
  @include media-breakpoint-up(lg) {
    max-width: 970px;
  }

  .disclaimer {
    font-size: 0.8rem;
  }

  .detailTitle {
    font-weight: bold;
  }

  .DDEventsPlanLink {
    color: $blue-2;
  }

  .modal-title {
    display: inline-flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
  }

  .narrative-download-button {
    float: right;
  }

  .messagesStartDate {
    font-style: italic;
    margin-bottom: 0;
  }
}

.doc-title-row {
  justify-content: space-between;
  width: 100%;
  .menuTitle {
    flex-grow: 1;
  }
}

.managersSearch {
  flex: 1;
  flex-grow: 1;
  justify-content: flex-end;
  font-size: 0.9em;
  max-width: 500px;
  min-width: 290px;
  .input-group {
    display: inline-flex;
    .no-rows-message {
      text-align: center;
    }
    .dropdown-menu {
      transform: translate3d(0px, 38px, 0px) !important;
      *:hover:not(.no-rows-message) {
        background-color:#16709E;
        color: #ffffff;
      }
    }
  }
  .doctype-option {
    padding: 0.5rem;
    cursor: pointer;
  }
  .doctype-option:hover {
    background-color: #deebff;
  }
}
.hideManagersSearch {
  display: none;
}

.fixGridSize {
  height: 10px;
}

.landingPageChart {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  g{
    min-width: 25rem;
  }
  text {
    min-width: 20rem;
  }
  svg {
    overflow: visible;
  }
}

.landingPageChartTooltip {
  position: absolute;
  z-index: 999999;
  display: none;
  pointer-events: none;
  background-color: #CCCCCCAA;
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 0;
  line-height: 5px;
}

.ag-row-odd {
  background-color: rgb(238, 236, 225) !important;
}

.Strategies .ag-header-cell-text {
    white-space: break-spaces;
}

.ag-header-cell-text-right {
  padding-left: 68px;
}
.rating-header {
  text-wrap: balance;
}

.fpl-nepc-strategy {
  h3 {
    margin: 2rem 0rem 0 0;
  }
}

@media only screen and (min-width: 1046px) { 
  .landingPageChart {
    justify-content: flex-start;
    gap: 5%;
  }
}
