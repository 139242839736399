.tilePlan {
  min-width: 281px;
  width: 30%;
  max-width: 360px;
  margin: 1em;
  border: 2px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem 1rem 1.5rem;
  text-align: center;

  p {
    font-size: 1rem;
    color: $gray-1;
    font-family: $fontMain;
  }

  .btn-success {
    @include button-variant($blue-2, $blue-2);
    font-weight: 700;
    background-image: none;
  }

  h2 {
    font-size: 1.5rem;
    text-align: center;
    text-transform: uppercase;
    font-family: $fontMain;
    font-weight: bold;
  }

  .goBtn {
    margin-top: auto !important;
    background: #16709e;
    color: #fff;
    display: block;
    padding: 1rem 0.5rem;
    font-size: 1.5rem;
    text-align: center;
  }

  .goBtn:hover {
    text-decoration: none;
  }

  .crmLink {
    outline:  none;
  }

  @media screen and (max-width: 800px) {
    width: 25% !important;
    max-width: 25vw !important;
  }
}
