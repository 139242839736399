.loaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000000;

  .spinnerContainer {
    position: absolute;
    top: 40%;
    left: 50%;
    margin-top: -20px;
    margin-left: -25px;
    text-align: center;
    font-size: 10px;
  }

  .spinnerIcon {
    @include spinner(1.5s, 5rem, border-width 8px, $blue-2);
  }
}

.loaderWrapperInGrid {
  z-index: 100000000;

  .spinnerContainer {
    position: absolute;
    top: 40%;
    left: 50%;
    margin-top: -20px;
    margin-left: -25px;
    text-align: center;
    font-size: 10px;
  }

  .spinnerIcon {
    @include spinner(1.5s, 5rem, border-width 8px, $blue-2);
  }
}
