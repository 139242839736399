.employeeHub{
  padding: 0rem 1.25rem 1.25rem 1.25rem;

  .messagesGrid {
    margin-top: 0,5rem !important;
    height: 40em;
    width: 100%;
  }

  .consultingResourcesInputSearch{
    width: 100%;
    height: 50px;

    .consultingResourcesInputBar {
      max-width: 300px;
    }
  }

  .consulting-resources-top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    @media screen and (max-width: 870px) {
      flex-direction: column;
    }
  }

  .consultingResourcesTab {
    margin-bottom: 15px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;


    @media screen and (max-width: 870px) {
      order: 1;
    }
  }

    .switchGroup {
      display: inline-flex;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto;
      align-self: auto;
      width: 28em;
  
      position: absolute;
      right: 14px;
      margin-top: 10px;
      justify-content: flex-end;
  
      @media screen and (max-width: 870px) {
        position: initial;
        align-self: flex-end;
        padding: 0px 45px;
        order: 0;
      }
  
      :nth-child(1) {
        min-width: 9.8em;
      }
  
      .custom-control-label {
        min-width: 11em;
      }
    }

  .consultingResourcesInputSearchDDE {
    width: 100%;
    height: 50px;
  }

  .disclaimer { 
    font-size: 13px;
    font-style: italic;
  }

  .gridArea {
    height: 32rem;
  }
  
  .gridArea button {
    margin-right: 1.5rem;
    margin-top: -0.4rem;
    font-size: 12px;
  }
      
  .gridLink {
    padding-left: 0px !important;
    color: $blue-2,
  }
  
  .ddgrid {
    .printItem {
      display: none;
    }
  }

  .container {
    max-width: 100% !important;
  }

  .menuTitle {
    flex-grow: 1;
    margin-bottom: 30px;
  }

.downloadCard {
  max-height: 90px;
  max-width: 250px;
  right: 14px;
  padding: 4px;
  .downloadText {
    font-style: italic;
    font-size: 14px;
    margin-top: 5px;
  }
}
  .custom-control-label:before{
    background-color: #0d6efd;
  }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
  }
  .custom-switch .custom-control-input ~ .custom-control-label::after {
    background-color: #fff;
  }
}

.employeePlanMeetingNotesLink {
  padding: 0 0.5em 0 0;
}

.investmentTab {
  .close-Button {
    position: absolute;
    right: 31px;
    margin: 10px;
    z-index: 1;
  }

  .row.static-fields,
  .row.dynamic-fields {
    justify-content: flex-start;
    margin: 0 !important;
  }

  .filters {
    width: 100%;
    position: relative;

    .filters-component {
      flex-direction: column;
      align-items: baseline;
      flex-wrap: wrap;
      margin: 5px 0 0 0;
      .filters-label {
        margin: 0 1rem 0 0;
        padding: 5px 0 0 0;
      }
      .filters-label-md {
        padding: 5px 0 0 0;
        min-width: 86px;
      }
      .filters-selects {
          display: inline-flex;
          padding: 0px;
          margin: 0 1rem 0 0;
          .btn {
            white-space: nowrap;
            padding: 1px 5px;
          }
          .dropdown-toggle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-width: 13rem;
          }
          .dropdown-menu {
            min-width: 13rem;
            max-height: 40rem;
            overflow-x: hidden;
          }
          .dropdown-item {
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p {
            margin: 0 1rem;
          }
        }
      .filters-label {
        margin: 0 1rem 0 0;
      }
      .filters-selects {
        margin: 0 1rem 0 0;
        .btn {
          padding: 1px 5px;
        }
      }
    }

    .filterButton {
      margin-right: 0.25rem;
      margin-top: 0.25rem;
      transition: color 0.10s ease-in-out, background-color 0.10s ease-in-out, border-color 0.10s ease-in-out, box-shadow 0.10s ease-in-out;
    }

    .selected {
      background-color: $blue-2;
      color: white;
    }
  }
  
  .strategyDownloadDetail {
    .detailTitle {
      flex-grow: 1;
      font-weight: bold;
    }
    .detailUpdatedOn {
      font-weight: normal;
    }

    .DDEventsPlanLink {
      color: $blue-2;
    }
  }
  
  margin-top: 10px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding:20px;

  .printItem {
    display: none;
  }
}