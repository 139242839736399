.content {
  z-index: 100;
  background-color: white;
  min-height: calc(100vh - #{$footer-big-height} - #{$header-big-height});

  @include media-breakpoint-up(md) {
    min-height: calc(100vh - #{$footer-small-height} - #{$header-small-height});
  }

  &:after {
    display: block;
    content: "";
    height: 10px;
  }
}

