.teamMember {
  padding: .5em;

  img {
    max-width: 110px;
  }

  .media-body {
    padding-left: 1em;
  }

  .media-heading {
    font-family: $fontMain;
    font-weight: bold;
    font-size: $font-m;
  }
}