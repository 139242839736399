.feedback-icon {
  position: fixed;
  right: 2rem;
  bottom: 3rem;
  z-index: 1050;

  i {
    font-size: 2.2rem;
    cursor: pointer;
    color: $blue-2;
  }
}

.feedback-modal {
  i {
    font-size: 1.75rem;
  }

  textarea {
    width: 100%;
  }
}

.btn-outline-secondary {
  color: $blue-2;
  border-color: $blue-2;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active {
  background-color: $blue-2;
  border-color: $blue-2 !important;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: $blue-2 !important;
  border-color: $blue-2 !important;
}

.submitBtn {
  background-color: $blue-2;
}

.submitBtn:disabled {
  background-color: $gray-2;
}

.rating {
  margin: 1rem auto;
  max-width: 30rem;

  .legend {
    display: flex;
  }

  .least, .most {
    color: #696969;
    font-size: 0.8em;
  }

  .most {
    margin-left: auto;
    justify-content: flex-end;
  }
}
