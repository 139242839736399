.planRecent {
  .gridLink {
    padding: 0 0;
    color: $blue-2;
  }

  .noRecentDocuments {
    margin-left: 1rem;
  }

  .recentDocumentsTitle {
    padding-left: .5rem;
    padding-top: 0.5rem;
    font-size: 17px;
  }

  .recentDocumentPublished {
    font-size: small;
  }

  .refreshDocuments {
    color: #808080;
    font-size: smaller;
  }

  .refreshDocumentsContainer {
    float: right;
    padding: 0em 0.7em;
    @media screen and (max-width: 1500px) {
      padding: 0em 0.5em;
    }
  }
}
