.authContainer {
  background-color: $blue-1;
  width: 100%;
  height: 520px;

  .imgContainer {
    position: absolute;
    top: 0;
    opacity: 0.5;
    z-index: 1;
    width: 100%;
    height: 520px;
    min-height: 520px;
    overflow-y: hidden;
  }

  .background {
    z-index: 0;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }

  .widgetContainer {
    background: none;
    position: absolute;
    top: 0;
    z-index: 10;
    max-height: 520px;


    button.authContainer-btn,
    a.registration-link,
    .button-primary,
    .registration-link,
    #okta-sign-in .registration-container .registration-link,
    #okta-sign-in .button-wide,
    #okta-sign-in .button-wide.button-primary,
    #okta-sign-in .button-primary {
      @include button-variant($blue-2, $blue-2);
      display: block;
      width: 100%;
      height: 3em;
      font-family: $fontMain !important;
      font-size: 1.125rem !important;
      font-weight: bold;
      border-radius: 0;
      margin: auto;
      line-height: 3em;
      background-image: none;
    }

    .clientHub {
      text-align: center;
      color: white;
      margin-top: 4%;

      .logo {
        background-image: url('/public/logo.jpg');
        background-repeat-y: no-repeat;
        background-repeat-x: no-repeat;
        margin: auto;
        width: 290px;
        height: 60px;
        padding-top: .5em;
      }
    }

    #okta-nepc-authContainer-form {
      div {
        margin-bottom: 0.5rem;
      }

      .form-group {
        width: 100%;
      }

    }

    #okta-sign-in {
      margin-top: 0;
      border-color: #ddd #ddd #d8d8d8;
      box-shadow: 0 2px 0 hsla(0, 0%, 69%, .12);

      ul {
        display: block !important;
      }

      h2.okta-form-title {
        font-size: 1.5rem;
      }

      h2,
      h3 {
        color: $blue-2;
      }

      a.js-help,
      a.js-help-link {
        display: none;
      }

      a.link.js-forgot-password,
      .forgot-password a.js-back {
        color: $blue-3;

        &:hover {
          color: $gray-2;
        }
      }

      .registration-container {
        .content-container {
          text-align: center;
          padding: 1rem 0 !important;
        }
      }

      .o-form-explain.o-form-input-error {
        color: #ce2a2a;
      }
    }
  }
}

.widgetContainer {
  .registration-container {
    .content {
      transition: padding-top .4s;
      border-top: 1px solid #ddd;
      margin-top: 5px;
      text-align: center;
      padding: 1rem 0 !important;
      min-height: 0 !important;
    }
  }
}