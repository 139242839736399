.main {
  padding: 0;
  min-width: 480px;
  position: relative;
}

.printOnly {
  display: none !important;
}

.ag-theme-alpine .ag-icon {
  color: $blue-2;
  font-size: 20px;
  font-weight: 700;
}

.ag-theme-alpine .ag-header-row {
  font-weight: 500;
  font-size: 14px;
}