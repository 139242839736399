.footer {
  min-width: 100%;
  height: $footer-big-height;

  background-color: $black-3;
  padding: .25em;
  z-index: 9999;

  @include media-breakpoint-up(md) {
    height: $footer-small-height;
  }

  .nav-link, .buildId {
    color: white;
    font-size: 1.125rem;
    display: inline-block;
    padding: 0.5rem;
  }

  .buildId {
    float: right;
     font-size: small;
  }

  .pipe {
    color: $gray-3;
    margin: 0 1.25rem;
    position: relative;
  }
}
